.appie-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appie-loader {
  opacity: 0;
}

.appie-loader.active {
  opacity: 100;
}

.appie-visible {
  transition: all 0.5s ease-in-out;
}

.appie-visible {
  opacity: 0;
}

.appie-visible.active {
  opacity: 100;
}

/* .appie-home-loader {
  transition: all 0.5s ease-in-out;
} */
.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20 px;
  overflow: hidden;
  transition: all linear 0.65s;
}

.appie-fun-fact-box .appie-fun-fact-content .appie-fun-fact-item .title span {
  font-size: 30px !important;
  color: #fff !important;
}

.home-four-project {
  background: #eef1f6;
}

.home-four-footer {
  background: #ffffff;
}

.slick-dots li button:before {
  content: "";
}

.appie-showcase-item {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px;
  }
}

.appie-services-2-area.appie-services-8-area {
  overflow: hidden;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .appie-services-8-area .service-thumb {
    margin-left: 0;
    margin-right: 0;
  }
}

.save-comment input:checked+label::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
}

.appie-testimonial-area {
  overflow: hidden;
}

.testimonial-about-slider-active .testimonial-parent-item {
  display: flex !important;
  justify-content: center;
}

.testimonial-box {
  width: 770px !important;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item .thumb img {
  width: 70px;
  display: inline-block;
}

.testimonial-box-about-slider-small-active .item .thumb img {
  display: inline-block;
  width: 40px;
}

.testimonial-box-about-slider-small-active .item .thumb {
  align-items: center;
}

.testimonial-box-about-slider-small-active .slick-center.slick-current .item {
  margin-top: -20px;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .testimonial-about-slider-active .testimonial-box {
    box-shadow: none;
    background: none;
  }
}

@media only screen and (min-width: 770px) and (max-width: 1024px) {
  .testimonial-about-slider-active .slick-arrow.next {
    right: 15px;
  }

  .testimonial-about-slider-active .slick-arrow.prev {
    left: 15px;
  }
}

/* Custom CSS */
.btn-gradient {
  color: #fff;
  background: rgb(68, 18, 216);
  /* Old browsers */
  background: -moz-linear-gradient(left, rgba(68, 18, 216, 1) 0%, rgba(107, 48, 178, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(68, 18, 216, 1) 0%, rgba(107, 48, 178, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(68, 18, 216, 1) 0%, rgba(107, 48, 178, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4412d8', endColorstr='#6b30b2', GradientType=1);
  /* IE6-9 */
}

.btn-gradient:hover {
  color: #fff;
}

.bannerImg img {
  width: 450px;
}

.bannerImg:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: -40px;
  right: 35px;
  bottom: -10px;
  left: 35px;
  background: linear-gradient(-45deg, #6C30B2 0%, #4312D9 100%);
  transform: translate3d(0px, 20px, 0) scale(0.93);
  filter: blur(20px);
  opacity: var(0.7);
  transition: opacity 0.3s;
  border-radius: inherit;
}

.bannerImg::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}

.social_ico img {
  width: 40px;
}

.appie-team-item {
  padding: 25px;
}

.glow {

  background-color: #2a2a3d;
  color: #fff;
  /* box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #642CA3, 0 0 10px #642CA3,
    0 0 10px #642CA3, 0 0 10px #642CA3; */
}

@-webkit-keyframes blink {
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #642CA3,
      0 0 10px #642CA3, 0 0 30px #642CA3;
  }
}

@keyframes blink {
  100% {
    box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #642CA3,
      0 0 10px #642CA3, 0 0 30px #642CA3;
  }
}

/* ================ The Timeline ================ */
.timeline {
  position: relative;
  width: 660px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: ' ';
  display: block;
  width: 6px;
  height: 100%;
  margin-left: -3px;
  background: rgb(80, 80, 80);
  background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(30, 87, 153, 1)), color-stop(100%, rgba(125, 185, 232, 1)));
  background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);
  background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, rgb(80, 80, 80) 8%, rgb(80, 80, 80) 92%, rgba(80, 80, 80, 0) 100%);

  z-index: 5;
}

.timeline li {
  padding: 1.8em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: right;
}

.flag-wrapper {
  position: relative;
  display: inline-block;

  text-align: center;
}

.flag {
  position: relative;
  display: inline;
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.direction-l .flag {
  -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-r .flag {
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-l .flag:before,
.direction-r .flag:before {
  position: absolute;
  top: 50%;
  right: -42px;
  content: ' ';
  display: block;
  width: 24px;
  height: 24px;
  margin-top: -10px;
  background: #fff;
  border-radius: 50px;
  border: 4px solid #4e2dab;
  z-index: 10;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
}

.direction-r .flag:before {
  left: -40px;
}

.time-wrapper {
  display: inline;

  line-height: 1em;
  font-size: 0.66666em;
  color: #4e2dab;
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248, 248, 248);
}

.desc {
  margin: 1em 0.75em 0 0;

  font-size: 0.77777em;
  font-style: italic;
  line-height: 1.5em;
}

.direction-r .desc {
  margin: 1em 0 0 0.75em;
}

/* ================ Timeline Media Queries ================ */

@media screen and (max-width: 660px) {
  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  .timeline li {
    padding: 2em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;
    z-index: 5;
    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
    left: 49%;
    content: ' ';
    display: block;
    width: 22px;
    height: 22px;
    margin-left: -9px;

    border-radius: 50px;
    border: 4px solid #4e2dab;
    z-index: 10;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.20);

    z-index: 15;
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;
    z-index: 15;
  }

}

@media only screen and (min-width: 400px) and (max-width: 660px) {

  .direction-l .desc,
  .direction-r .desc {
    margin: 1em 4em 0 4em;
  }

  .appie-team-item {
    margin: 50px;
  }

  .footerSection {
    text-align: center;
    padding-top: 0px;
  }

  .footerSection .logo {
    margin: 10px
  }

  .footerSection .text-right {
    text-align: center !important;
  }
}

.timer-app {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.title {
  font-size: 36px;
}

.timer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.timer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 12px;
}

.timer-1 {
  font-size: 14px;
  color: white;
}

.semicolon {
  font-size: 36px;
}